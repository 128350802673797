import React from "react"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import JumboPhoto from "../../../components/jumbophoto"
import { Container, Row, Col } from "react-bootstrap"

export default () => <Layout>
	<Helmet title="File Bankruptcy in Alabama | Gina Mcdonald &amp; Associates, L.L.C." defer={false} />

	<JumboPhoto/>

	<Container>
		<Row>
			<Col>
				<h1 className="text-primary">File Bankruptcy in Alabama</h1>
				<hr/>
				<p>Most bankruptcy cases begin when a debtor files a petition in bankruptcy court seeking relief from creditors. A debtor cannot file unless he or she has, within 180 days before filing, received credit counseling from an approved credit counseling agency. Once a bankruptcy petition is filed, an “estate” is created, which includes all legal or equitable interests of the debtor in property, and an automatic stay prevents the IRS or other creditors from taking further collection efforts against the debtor. The debtor is not allowed to transfer property that has been declared part of the estate subject to proceedings. Furthermore, certain pre-proceeding transfers of property, secured interests, and liens may be delayed or invalidated. For a debtor, bankruptcy can offer immediate temporary relief from creditor pressure, long-term relief by allowing the debtor to extend the time for payment of a debt, and permanent relief by discharging debts. However, there are certain types of debts that bankruptcy cannot get rid of, non-dischargeable debt, which generally includes child support, most student loans, and most tax debts.</p>
				<p>In Alabama, there are 3 district bankruptcy courts, the <a href="http://www.alnb.uscourts.gov">Northern District</a>, which has locations in Birmingham, Tuscaloosa, Decatur, and Anniston; the <a href="http://www.almb.uscourts.gov">Middle District</a>, which has locations in Montgomery, Dothan, and Opelika; and the <a href="http://www.alsb.uscourts.gov">Southern District</a>, which has locations in Selma and Mobile. The court websites have information on court locations, court rules, and filing procedures.</p>
				<p>The first step is to determine if you should file for bankruptcy. Then, the means test, which takes into account the debtor’s income and the amount and nature of debts, is used to determine which type of bankruptcy an individual debtor should file for. Then, the case will be filed in the court that serves his or her area.</p>
				<p>Most of the bankruptcy process is administrative and will not involve going to the courtroom and seeing the bankruptcy judge. Within 30-45 days after filing a Chapter 7 bankruptcy, a debtor will have what is called a 341 Meeting of the Creditors with the bankruptcy court trustee, and any creditors that wise to appear. Prior to the meeting, the trustee will review the debtor’s filing papers and financial information. At the meeting, the trustee will ask questions about information in the bankruptcy paperwork to try to determine if the debtor has any nonexempt property he or she can sell, or if the debtor has made any payments to creditors or transferred any money or property before bankruptcy that he or she can get back.</p>
				<p>He will also determine if the debtor is actively suing a person or entity that would result in the debtor receiving a windfall of money.</p>
				<p>After the meeting, the Trustee will enter a “no asset” report or an “asset” report. Upon the completion of the final Personal Management Course and the expiration of 90 days from file date, most cases are discharged and closed.</p>
			</Col>
		</Row>
	</Container>
</Layout>